"use client";

import React, { useImperativeHandle, useRef } from "react";

import ReactPlayerLoader from "@brightcove/react-player-loader";

import "./BrightCoveVideo.scss";

import ContentfulRichText from "../ContentfulRichText/ContentfulRichText";

interface BrightcoveVideoProps {
	videoId: string;
	caption?: any;
	playerId?: string;
}

export type BrightcoveVideoRef = {
	pause: () => void;
};

const BrightcoveVideo = React.forwardRef<
	BrightcoveVideoRef,
	BrightcoveVideoProps
>(
	(
		{
			videoId,
			caption,
			playerId = process.env.NEXT_PUBLIC_BRIGHTCOVE_PLAYER_ID,
		},
		ref,
	) => {
		const iframeRef = useRef<HTMLIFrameElement>(null);
		var myPlayer: {
			pause: () => void;
		};
		useImperativeHandle(
			ref,
			() => ({
				pause: () => {
					myPlayer.pause();
					iframeRef.current?.contentWindow?.postMessage(
						"pauseVideo",
						window.location.protocol + "//players.brightcove.net",
					);
				},
			}),
			[],
		);

		return (
			<>
				<ReactPlayerLoader
					accountId={
						process?.env?.NEXT_PUBLIC_BRIGHTCOVE_ACCOUNT_ID ??
						"UNDEFINED"
					}
					videoId={videoId}
					attrs={{
						className:
							"[&_video-js]:aspect-16/9 [&_video-js]:rounded-[24px] [&_video-js]:w-full",
						services: {
							facebook: true,
							x: true,
							tumblr: true,
							pinterest: true,
							linkedin: true,
						},
					}}
					playerId={playerId}
					embedOptions={{
						responsive: true,
						deeplinking: true,
						services: {
							facebook: true,
							x: true,
							tumblr: true,
							pinterest: true,
							linkedin: true,
						},
					}}
					options={{
						playsinline: true,
					}}
					onSuccess={(success) => {
						myPlayer = success.ref;
					}}
				/>
				{caption && (
					<ContentfulRichText
						document={caption}
						className='ch-txt is-headline ch-ts--3'
					/>
				)}
			</>
		);
	},
);
BrightcoveVideo.displayName = "LegacyBrightcoveVideo";

export default BrightcoveVideo;

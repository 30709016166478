import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
	BLOCKS,
	INLINES,
	MARKS,
	Document as RichTextDocument,
} from "@contentful/rich-text-types";

import BrightcoveVideo from "../BrightcoveVideo/BrightcoveVideo";
import Carousel from "../Carousel/Carousel";
import ContentfulRTFImage from "../ContentfulRTFImage";

type RichTextProps = {
	document: RichTextDocument | undefined;
};

const renderBoldMark = (text: React.ReactNode) => {
	return <strong>{text}</strong>;
};

const renderEmbeddedAsset = (node: any) => {
	return <ContentfulRTFImage fields={node.data.target.fields} />;
};

const renderEmbeddedEntry = (node: any) => {
	const contentType = node?.data?.target?.sys?.contentType?.sys?.id;
	const mediaBlock = "mediaBlock";
	const mediaCollection = "mediaCollection";

	if (![mediaBlock, mediaCollection].includes(contentType)) {
		return null;
	}

	const fields = node.data.target.fields;

	if (contentType === mediaBlock) {
		return fields.brightcoveId ? (
			<BrightcoveVideo
				videoId={fields.brightcoveId}
				caption={fields.caption}
			/>
		) : (
			<ContentfulRTFImage fields={fields} />
		);
	} else if (contentType === mediaCollection) {
		return <Carousel slides={fields.slides} />;
	}
	return null;
};

const renderOptions = {
	renderMark: {
		[MARKS.BOLD]: (text: React.ReactNode) => renderBoldMark(text),
	},
	renderNode: {
		[INLINES.EMBEDDED_ENTRY]: renderEmbeddedEntry,
		[BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
		[BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry,
	},
	preserveWhitespace: true,
};

export type ContentfulRichTextProps = RichTextProps & { className?: string };

export const ContentfulRichText = ({
	document,
	className,
}: ContentfulRichTextProps) => {
	if (!document || document.nodeType !== "document") return null;

	return (
		<div className={`is-rich-text ${className ?? ""}`}>
			{documentToReactComponents(document, renderOptions)}
		</div>
	);
};

export default ContentfulRichText;

"use client";

import { useEffect } from "react";

import { env } from "@/env";
import markerSDK from "@marker.io/browser";

export function MarkerIO() {
	useEffect(() => {
		if (
			env.NEXT_PUBLIC_MARKERIO_ENABLED &&
			env.NEXT_PUBLIC_MARKERIO_PROJECTID
		) {
			markerSDK.loadWidget({
				project: env.NEXT_PUBLIC_MARKERIO_PROJECTID,
			});
		}
	}, []);

	return null;
}

"use client";

import React, { useImperativeHandle, useMemo, useRef } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";

import Slider from "react-slick";

import BrightcoveVideo, {
	type BrightcoveVideoRef,
} from "../BrightcoveVideo/BrightcoveVideo";
import ContentfulRTFImage from "../ContentfulRTFImage";

// @ts-ignore
const SliderComponent = Slider.default ? Slider.default : Slider;

export type SlideContentProps = {
	fields: {
		brightcoveId?: string;
		caption?: any;
		[key: string]: any;
	};
};

const SlideContent = React.forwardRef<BrightcoveVideoRef, SlideContentProps>(
	({ fields }, ref) => {
		const videoRef = useRef<{ pause: () => void }>(null);

		useImperativeHandle(ref, () => ({
			pause: () => {
				videoRef.current?.pause();
			},
		}));

		//handles archived slide content
		if (!fields) return null;

		const { brightcoveId, caption } = fields;
		return (
			<>
				{brightcoveId ? (
					<BrightcoveVideo
						ref={videoRef}
						videoId={brightcoveId}
						caption={caption}
					/>
				) : (
					<ContentfulRTFImage fields={fields} />
				)}
			</>
		);
	},
);
SlideContent.displayName = "SLideContent";

const settings = {
	dots: false,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const Carousel: React.FC<{
	slides: { sys: { id: string }; fields: SlideContentProps["fields"] }[];
}> = ({ slides }) => {
	const slideRefs = useRef<BrightcoveVideoRef[]>([]);

	const carouselSlides = (slides || []).filter(Boolean);
	if (carouselSlides.length === 0) {
		return null;
	}
	if (carouselSlides.length === 1) {
		return <SlideContent fields={carouselSlides[0].fields} />;
	}

	return (
		<SliderComponent
			{...settings}
			beforeChange={(oldIndex: number) => {
				slideRefs.current[oldIndex]?.pause?.();
			}}
			className='carousel-container'
		>
			{carouselSlides.map((slide, index) => {
				return (
					<div key={slide.sys.id}>
						<SlideContent
							ref={(ref) => {
								slideRefs.current[index] =
									ref as BrightcoveVideoRef;
							}}
							fields={slide.fields}
						/>
					</div>
				);
			})}
		</SliderComponent>
	);
};

export default Carousel;

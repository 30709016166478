import Image from "next/image";

import ContentfulRichText from "./ContentfulRichText/ContentfulRichText";

const ContentfulRTFImage = ({ fields }: any) => {
	interface IImage {
		url: string;
		height: number;
		width: number;
		alt: string;
		caption: any;
	}

	const renderImage = ({ url, height, width, alt, caption }: IImage) => {
		const protocol = url.includes("http");
		return (
			<>
				<Image
					src={`${!protocol ? "https://" + url : url}`}
					height={height}
					width={width}
					alt={alt || "Image"}
				/>
				{caption && (
					<ContentfulRichText
						document={caption}
						className='ch-txt is-headline ch-ts--3'
					/>
				)}
			</>
		);
	};

	const extractImageDetails = (fields: any) => {
		const { image, secondaryAssetUrl, caption } = fields;
		const imageNode = image?.[0] || null;

		if (imageNode) {
			//thumbnails?.webimage used as a fallback while we standardize assets with original property
			return {
				url: imageNode.original
					? imageNode.original
					: imageNode.thumbnails?.webimage,
				height: imageNode.height,
				width: imageNode.width,
				description: imageNode.description,
				caption: caption,
			};
		} else {
			return {
				url: secondaryAssetUrl,
			};
		}
	};

	const { url, height, width, description, caption } =
		extractImageDetails(fields);
	return url
		? renderImage({ url, height, width, alt: description, caption })
		: null;
};
export default ContentfulRTFImage;

"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

import { env } from "@/env";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export function AzureAppInsights() {
	const [appInsights, setAppInsightsObject] = useState<ApplicationInsights>();
	const pathname = usePathname();

	useEffect(() => {
		if (env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING) {
			const appInsights = new ApplicationInsights({
				config: {
					connectionString:
						env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,
				},
			});
			appInsights.loadAppInsights();

			// Any on start tasks can be done here; all others should run through the app insights object in hooks or via the
			// global setter so that it can be referenced for the common logger

			window.appInsights = appInsights;
			setAppInsightsObject(appInsights);
		}
	}, []);

	// Track Page View
	useEffect(() => {
		if (appInsights) {
			appInsights.trackPageView();
		}
	}, [appInsights, pathname]);

	return null;
}
